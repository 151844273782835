@import "bootstrap/scss/functions";

@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/helpers";

@import "bootstrap/scss/utilities/api";
@import "bootstrap";

.dark {
  $enable-gradients: false;

  /* redefine theme colors for dark theme */
  $primary: #012345;
  $secondary: #111111;
  $success: #222222;
  $dark: #000;

  $theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "danger": $danger,
    "info": $indigo,
    "dark": $dark,
    "light": #aaa,
  );

  /* redefine theme color variables */
  @each $color, $value in $theme-colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }

  $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

  @each $color, $value in $theme-colors-rgb {
    --#{$variable-prefix}#{$color}-rgb: #{$value};
  }

  $body-color: #eeeeee;
  $body-bg: #263c5c;

  --#{$variable-prefix}body-color: #{$body-color};
  --#{$variable-prefix}body-bg: #{$body-bg};

  @import "bootstrap";
}

@import "react-widgets/scss/styles.scss";
@import "bootstrap-icons/font/bootstrap-icons.css";
